import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { GLOBAL } from "../styles/global";
import { FilterPill } from "./FilterPill.component";
import { LeaderboardEntry } from "./LeaderboardEntry.component";
import { Leaderboard as LeaderboardType } from "../types/server-types";

export const Leaderboard = ({ data }: { data: LeaderboardType[] }) => {
  const [activePill, setActivePill] = useState(0);
  return (
    <View style={styles.leaderboardContainer}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          marginBottom: 18,
        }}
      >
        <Text style={styles.leaderboardHeaderText}>Leaderboard</Text>
        {data.map((item, index) => {
          return (
            <FilterPill
              key={index}
              active={activePill === index}
              title={item.title}
              onPress={() => {
                setActivePill(index);
              }}
            />
          );
        })}
      </View>
      <View>
        {data[activePill].leaderboardData?.map((item, index) => {
          return (
            <LeaderboardEntry
              key={index}
              number={index + 1}
              title={item.name}
              value={item.value}
              type={data[activePill].type}
            />
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  leaderboardContainer: {
    backgroundColor: GLOBAL.COLORS.white,
    borderRadius: 15,
    paddingHorizontal: 20,
    paddingVertical: 30,
    shadowOffset: { width: 4, height: 8 },
    shadowColor: "#295266",
    shadowOpacity: 0.1,
    shadowRadius: 17,
    flex: 1,
  },
  leaderboardHeaderText: {
    fontFamily: GLOBAL.FONT.Secondary,
    color: GLOBAL.COLORS.primary_1,
    fontSize: 18,
    flex: 1,
  },
});
