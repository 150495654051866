import React, { useState } from "react";
import MasonryList from "@react-native-seoul/masonry-list";
import { View, Text, StyleSheet } from "react-native";
import { MosaicTile } from "./MosaicTile.component";
import { NewsFeed } from "../types/server-types";
import { GLOBAL } from "../styles/global";
import { FilterPill } from "./FilterPill.component";

export const Newsfeed = ({ newsFeed }: { newsFeed: NewsFeed[] }) => {
  const [activePill, setActivePill] = useState(0);
  return (
    <View style={styles.newsFeedContainer}>
      <View style={{ flexDirection: "row", marginBottom: 20 }}>
        <Text style={styles.newsfeedHeaderText}>News feed</Text>
        {newsFeed.map((item, index) => {
          return (
            <FilterPill
              key={index}
              active={activePill === index}
              title={item.title}
              onPress={() => {
                setActivePill(index);
              }}
            />
          );
        })}
      </View>
      <MasonryList
        data={newsFeed[activePill].items}
        keyExtractor={(item): string => item.id}
        numColumns={3}
        showsVerticalScrollIndicator={false}
        renderItem={({ item, i }: { item: any; i: number }) => {
          return (
            <View
              style={{
                marginBottom: 4,
                overflow: "hidden",
              }}
            >
              <MosaicTile image={item.image} />
            </View>
          );
        }}
        refreshing={false}
        onEndReachedThreshold={0.1}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  newsFeedContainer: {
    backgroundColor: GLOBAL.COLORS.white,
    flex: 2,
    borderRadius: 15,
    marginRight: 40,
    paddingHorizontal: 20,
    paddingVertical: 30,
    shadowOffset: { width: 4, height: 8 },
    shadowColor: "#295266",
    shadowOpacity: 0.1,
    shadowRadius: 17,
  },

  newsfeedHeaderText: {
    fontFamily: GLOBAL.FONT.Secondary,
    color: GLOBAL.COLORS.primary_1,
    fontSize: 18,
    flex: 1,
  },
});
