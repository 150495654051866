import {
  SERVER_URL,
  MOCK_SERVER_URL,
  PAYMENT_SERVER_URL,
  PAYMENT_API_KEY,
  ADMIN_KEY,
} from "@env";

const getServerUrl = () => {
  const url = SERVER_URL || "http://api.tryhabitual.com/api/";
  return url;
};

export const getMockServerUrl = () => {
  return MOCK_SERVER_URL;
};

const timeoutExemptPaths = [""];

export type IAPIParams = {
  route: string;
  method?: string;
  body?: string;
  contentType?: string;
  userToken?: string;
  useMock?: boolean;
  showToast?: boolean;
};

export const callApi = async (
  params: IAPIParams,
  usePaymentServer = false,
  showToast = false
) => {
  const baseUrl = usePaymentServer ? PAYMENT_SERVER_URL : getServerUrl();

  const headers: any = {
    "Content-Type": params.contentType || "application/json",
    "admin-key": usePaymentServer ? PAYMENT_API_KEY : ADMIN_KEY,
  };

  if (params.userToken) {
    headers.Authorization = `Bearer ${params.userToken}`;
  }

  const method = params.method || "GET";
  const body = params.body || null;

  let timeout: any = null;
  let timedOut = false;
  let response: Response;
  try {
    // eslint-disable-next-line no-undef
    const abortController = new AbortController();
    if (
      timeoutExemptPaths.some(
        (exemptStr) => params.route.indexOf(exemptStr) >= 0
      ) === false
    ) {
      timeout = setTimeout(() => {
        timedOut = true;
        abortController.abort();
      }, 60000);
    }
    response = await fetch(baseUrl + params.route, {
      method,
      headers,
      body,
      signal: abortController.signal,
    });
  } catch (err) {
    if (showToast) {
      console.log(
        "callApi FAILED ",
        method,
        baseUrl + params.route,
        headers.Authorization,
        err
      );
    }

    if (timedOut) {
      throw new Error();
    } else {
      if (err instanceof TypeError) {
        if (err.message === "Network request failed") {
          throw new Error();
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    }
  }

  if (timeout !== null) {
    clearTimeout(timeout);
  }

  if (response && !response.ok) {
    console.log(
      "callApi FAILED",
      method,
      baseUrl + params.route,
      headers.Authorization
    );
  } else {
    console.log(
      "callApi OK",
      method,
      baseUrl + params.route,
      response.status,
      headers.Authorization
    );
  }

  return response;
};
