import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { GLOBAL } from "../styles/global";

export const FilterPill = ({
  title,
  active,
  onPress,
  key,
}: {
  title: string;
  active: boolean;
  onPress: () => void;
  key: number;
}) => {
  return (
    <TouchableOpacity
      key={key}
      onPress={() => {
        onPress();
      }}
      style={[
        styles.container,
        active
          ? {
              backgroundColor: GLOBAL.COLORS.active_blue,
              borderColor: GLOBAL.COLORS.active_blue,
            }
          : {},
      ]}
    >
      <Text
        style={[styles.titleText, active ? { color: GLOBAL.COLORS.white } : {}]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: GLOBAL.COLORS.very_light_blue,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 100,
    marginLeft: 8,
    borderWidth: 1,
    borderColor: GLOBAL.COLORS.light_blue,
  },
  titleText: {
    fontFamily: GLOBAL.FONT.Tertiary,
    fontSize: 14,
    color: GLOBAL.COLORS.dark_blue,
  },
});
