import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, Text, View, Dimensions, Image } from "react-native";
import {
  useFonts,
  DMSans_400Regular,
  DMSans_400Regular_Italic,
  DMSans_500Medium,
  DMSans_500Medium_Italic,
  DMSans_700Bold,
  DMSans_700Bold_Italic,
} from "@expo-google-fonts/dm-sans";
import AppLoading from "expo-app-loading";
import {
  DMSerifDisplay_400Regular,
  DMSerifDisplay_400Regular_Italic,
} from "@expo-google-fonts/dm-serif-display";
import { GLOBAL } from "./src/styles/global";
import { StatItem } from "./src/components/StatItem.component";
import { FilterPill } from "./src/components/FilterPill.component";
import MasonryList from "@react-native-seoul/masonry-list";
import { callApi, IAPIParams } from "./src/helpers/api-helper";
import { API_ROUTE } from "./src/helpers/api-route";
import { DashboardData, NewsFeedItem } from "./src/types/server-types";
import { Leaderboard } from "./src/components/Leaderboard.component";
import { Header } from "./src/components/Header.component";
import { MosaicTile } from "./src/components/MosaicTile.component";
import { Newsfeed } from "./src/components/Newsfeed.component";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./src/helpers/firebaseHelper";
import { Login } from "./src/components/Login";

function App() {
  let [fontsLoaded] = useFonts({
    DMSans_400Regular,
    DMSans_400Regular_Italic,
    DMSans_500Medium,
    DMSans_500Medium_Italic,
    DMSans_700Bold,
    DMSans_700Bold_Italic,
    DMSerifDisplay_400Regular,
    DMSerifDisplay_400Regular_Italic,
  });
  const [screenData, setScreenData] = useState<DashboardData>();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!screenData) {
      const call: IAPIParams = {
        route: `${API_ROUTE.DASHBOARD}/`,
        method: "GET",
      };
      callApi(call)
        .then((response) => {
          if (response && response.ok) {
            return response.json();
          } else {
            console.log("ERROR");
          }
        })
        .then((responseData: any) => {
          setScreenData(responseData);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }, []);

  useEffect(() => {
    const call: IAPIParams = {
      route: `${API_ROUTE.DASHBOARD}/`,
      method: "GET",
    };
    const interval = setInterval(() => {
      callApi(call)
        .then((response) => {
          if (response && response.ok) {
            return response.json();
          } else {
            console.log("ERROR");
          }
        })
        .then((responseData: any) => {
          setScreenData(responseData);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (!fontsLoaded || !screenData) {
    return <AppLoading />;
  }

  if (!user) {
    return <Login />;
  } else
    return (
      <View style={styles.container}>
        <Header headerText={screenData.headerText} />
        <View style={styles.contentContainer}>
          <View style={styles.statsContainer}>
            {screenData.bannerData.map((item, index) => {
              return <StatItem key={index} item={item} />;
            })}
          </View>
          <View style={styles.middleContentContainer}>
            <Newsfeed newsFeed={screenData.newsFeed} />
            <Leaderboard data={screenData.leaderboard} />
          </View>
        </View>
      </View>
    );
}
let ScreenHeight = Dimensions.get("window").height;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: ScreenHeight,
    backgroundColor: "#E9F2F2",
  },
  contentContainer: { padding: 40 },
  statsContainer: {
    backgroundColor: GLOBAL.COLORS.white,
    borderRadius: 15,
    paddingVertical: 24,
    paddingHorizontal: 80,
    flexDirection: "row",
    justifyContent: "space-between",
    shadowOffset: { width: 4, height: 8 },
    shadowColor: "#295266",
    shadowOpacity: 0.1,
    shadowRadius: 17,
  },
  middleContentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
    alignItems: "flex-start",
  },
  newsFeedContainer: {
    backgroundColor: GLOBAL.COLORS.white,
    flex: 2,
    borderRadius: 15,
    marginRight: 40,
    paddingHorizontal: 20,
    paddingVertical: 30,
    shadowOffset: { width: 4, height: 8 },
    shadowColor: "#295266",
    shadowOpacity: 0.1,
    shadowRadius: 17,
  },

  newsfeedHeaderText: {
    fontFamily: GLOBAL.FONT.Secondary,
    color: GLOBAL.COLORS.primary_1,
    fontSize: 18,
    flex: 1,
  },
});
export default App;
