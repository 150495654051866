import React from "react";
import { View, Text, ViewStyle, StyleProp, StyleSheet } from "react-native";
import { GLOBAL } from "../styles/global";
import { LeaderboardType } from "../types/server-types";

export const ScoreTypeIcon = ({
  type,
  style,
  value,
}: {
  type: LeaderboardType;
  style?: StyleProp<ViewStyle>;
  value: number;
}) => {
  switch (type) {
    case LeaderboardType.COINS:
      return (
        <View style={[styles.itemContainer, styles.coinContainer, style]}>
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4_633)">
              <path
                d="M7.81712 0.772562C-2.16861 0.745927 -1.88105 14.1764 6.54519 14.2262C10.1645 14.2474 13.7274 12.1966 13.7274 7.49939C13.7274 1.91442 9.01154 0.919391 7.81712 1.00476C1.92385 1.42476 -0.227072 10.5917 5.66552 13.2421C5.66552 13.2421 3.86606 13.6676 3.70699 13.2954C3.62133 13.0939 4.23656 12.7634 4.23656 12.7634C4.23656 12.7634 2.8076 12.8167 2.8076 12.6036C2.8076 12.3905 3.28415 11.9657 3.28415 11.9657C3.28415 11.9657 2.11419 11.9323 1.80216 11.5403C1.59074 11.2746 2.4371 10.6893 2.4371 10.6893C2.4371 10.6893 1.25287 10.5548 1.06117 10.1041C0.902092 9.73188 2.01426 9.30642 2.01426 9.30642C2.01426 9.30642 0.796722 9.09403 0.690672 8.45549C0.584621 7.81695 1.72466 7.49871 1.72466 7.49871C1.72466 7.49871 0.690672 7.12651 0.743017 6.54125C0.785845 6.06456 1.96056 5.90339 1.96056 5.90339C1.96056 5.90339 0.955118 5.47793 1.21956 4.84007C1.21956 4.84007 1.3902 4.34632 2.62813 4.38046C2.62813 4.38046 1.85451 3.77676 2.11963 3.45715C2.38476 3.13754 3.1781 3.19149 3.1781 3.19149C3.1781 3.19149 2.75457 2.71276 3.07204 2.39383C3.23112 2.23403 4.13051 2.23403 4.13051 2.23403"
                stroke="#2C4A43"
                stroke-width="0.585366"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.96265 4.74081C5.97148 6.46384 5.98032 8.18686 5.98916 9.90921"
                stroke="#2C4A43"
                stroke-width="0.585366"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.18896 4.48763C9.19576 6.26597 9.20188 8.04363 9.20868 9.82197"
                stroke="#2C4A43"
                stroke-width="0.585366"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.99182 7.2404C7.2148 7.14343 7.915 7.13045 9.13934 7.20011"
                stroke="#2C4A43"
                stroke-width="0.585366"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4_633">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <Text style={styles.valueText}>{value}</Text>
        </View>
      );
    case LeaderboardType.STEPS:
      return (
        <View style={[styles.itemContainer, styles.stepsContainer, style]}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.2393 8.43185C13.6081 8.27156 14.9685 6.47677 14.9627 5.09557C14.9596 4.42984 14.6919 3.78464 14.7006 3.1183C14.7091 2.42031 15.0773 1.70144 15.7096 1.41062C16.3419 1.1198 17.2213 1.39499 17.4625 2.04947C17.7038 2.70396 17.2256 3.48159 16.5712 3.75105C15.9168 4.02052 15.164 3.8932 14.5009 3.64693C13.9248 3.43087 13.3587 3.10652 13.0247 2.58086C12.6906 2.05519 12.6687 1.31393 13.0968 0.869687C13.525 0.425449 14.4128 0.510914 14.6307 1.08704C14.7824 1.48968 14.5688 1.96098 14.2258 2.22013C13.8828 2.47928 13.4393 2.56588 13.0097 2.60038C12.5312 2.63909 12.0235 2.61556 11.61 2.3719C11.1965 2.12824 10.9129 1.60926 11.0718 1.1565C11.2307 0.703728 11.9258 0.504918 12.2228 0.88224C12.4419 1.16084 12.3469 1.5935 12.1008 1.84693C11.8547 2.10035 11.4982 2.21355 11.1527 2.28814C10.7835 2.36755 10.3915 2.41565 10.0347 2.29279C9.67781 2.16993 9.36768 1.84384 9.37718 1.46783C9.38667 1.09183 9.81571 0.756147 10.1544 0.919255C10.493 1.08236 10.5119 1.59021 10.282 1.88112C10.0522 2.17204 9.66664 2.28994 9.30392 2.36319C8.80582 2.4611 8.21141 2.46888 7.88384 2.08175C7.55626 1.69462 7.95157 0.904072 8.40812 1.1262C8.86468 1.34832 8.62261 2.01608 8.34188 2.4242C6.14663 5.54836 4.12053 8.78798 2.27229 12.1291C1.67524 13.2085 1.07946 14.4526 1.45499 15.6269C1.84634 16.8549 3.39591 17.5601 4.5772 17.0542C5.62142 16.6045 6.19021 15.462 6.4488 14.3551C6.68431 13.3413 7.00187 12.3484 7.39835 11.3861C8.19204 9.39625 10.2113 8.47801 12.2365 8.4363"
              stroke="#2C4A43"
              stroke-width="0.827609"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <Text style={styles.valueText}>{value}</Text>
        </View>
      );
    case LeaderboardType.WEIGHTLOSS:
      return (
        <View
          style={[
            styles.itemContainer,
            value >= 0
              ? styles.positiveWeightContainer
              : styles.negativeWeightContainer,
            style,
          ]}
        >
          <svg
            width="6"
            height="13"
            viewBox="0 0 6 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.46875 0.968892L3.46875 10.896L5.10525 9.25156C5.28788 9.06807 5.58467 9.06737 5.76816 9.24999C5.95167 9.43262 5.95235 9.72943 5.76975 9.91292L3.33225 12.3622C3.33211 12.3624 3.33195 12.3625 3.33181 12.3627C3.14918 12.5457 2.85143 12.5463 2.6682 12.3627C2.66806 12.3625 2.6679 12.3624 2.66775 12.3623L0.230257 9.91297C0.0476788 9.7295 0.0483114 9.43269 0.23185 9.25004C0.415342 9.06742 0.712131 9.06812 0.894756 9.25161L2.53125 10.896L2.53125 0.968892C2.53125 0.710002 2.74111 0.500142 3 0.500142C3.25889 0.500142 3.46875 0.710002 3.46875 0.968892Z"
              fill="#2C4A43"
            />
          </svg>
          <Text style={styles.valueText}>{value} kg</Text>
        </View>
      );
    default:
      return null;
  }
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 7,
    paddingHorizontal: 12,
    borderRadius: 100,
  },
  valueText: {
    marginLeft: 4,
    fontFamily: GLOBAL.FONT.Secondary,
    color: GLOBAL.COLORS.primary_1,
    fontSize: 14,
  },
  coinContainer: {
    backgroundColor: GLOBAL.COLORS.primary_2,
  },
  positiveWeightContainer: { backgroundColor: GLOBAL.COLORS.light_green },
  negativeWeightContainer: { backgroundColor: GLOBAL.COLORS.heart_red },
  stepsContainer: { backgroundColor: GLOBAL.COLORS.light_purple },
});
