const COLORS = {
  default: "#000",
  primary_1: "#2c4a43", // Dark Green
  primary_2: "#ffe768", // Yellow
  primary_3: "#5da7eb", // Blue
  primary_4: "#6ce4bf", // Green
  ground: "#F7FBFA", // Ground
  path: "#ebeee7", // Path
  hand_green: "#7ab9a6", // Handy Green
  brain_pink: "#d1a593", // Brainy Pink
  hab_purple: "#99A7EB",
  heart_red: "#ee8868", // Hearty red
  error: "#f0663b", // UI color for Error
  success: "#6ce4bf", // UI color for Success
  grey: "#e3e6df", // UI color for Grey
  white: "#ffffff", // UI color for White
  light_blue: "#acd2f6",
  light_green: "#b2f1dd",
  input_fill: "#fbfcfa", // UI color for background of input fields
  placeholderText: "#2c4a4333",
  autocompleteText: "#2c4a4399",
  majorGridLine: "#ebeee7",
  minorGridLine: "#f5f8f2",
  yellow: "#F6E171",
  light_yellow: "#fef1aa",
  very_light_yellow: "#fffae0",
  purple: "#99A7EB",
  light_purple: "#cfd5f2",
  very_light_purple: "#f7f8fd",
  very_light_blue: "#f1f7fd",
  very_light_green: "#ECFCF7",
  error_red: "#E16756",
  text_on_yellow: "#B69B10",
  completed_green: "#D6F4EB",
  label: "#c4c8c5",
  dark_background: "#1D332E",
  divider: "#C4C4C4",
  active_blue: "#519DE3",
  dark_blue: "#4F92CF",
  light_blue_grey: "#ECF4F4",
  habitual_dark_green: "#3BBD9E",
};

const FONT = {
  Primary: "DMSerifDisplay_400Regular",
  Secondary: "DMSans_700Bold",
  Tertiary: "DMSans_500Medium",
  DM_Sans_400: "DMSans_400Regular",
};

const GLOBAL = {
  COLORS,
  FONT,
};

export { GLOBAL };
