import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { GLOBAL } from "../styles/global";
import {
  DataMetric,
  DataMetricUnits,
  DataMetricValue,
} from "../types/server-types";
import { BannerDataIcon } from "./BannerDataIcon.component";

const IncreaseDeltaSvg = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 0L18.5933 15.75H0.406734L9.5 0Z" fill="#6CE4BF" />
    </svg>
  );
};
const DecreaseDeltaSvg = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 16L0.406733 0.250002L18.5933 0.25L9.5 16Z" fill="#EE8868" />
    </svg>
  );
};

export const StatItem = ({ item, key }: { item: DataMetric; key: number }) => {
  const { value, title, delta, type } = item;
  return (
    <View key={key} style={styles.container}>
      <Text style={styles.titleText}>{title}</Text>
      <View
        style={{
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <BannerDataIcon
          style={{ marginRight: 8, alignSelf: "center" }}
          type={type}
        />
        <Text style={styles.valueText}>
          {value.value}
          {value.unit === DataMetricUnits.PERCENT && (
            <Text style={styles.valuePercentageText}>%</Text>
          )}
          {value.unit === DataMetricUnits.WEIGHT && <Text>kg</Text>}
        </Text>
        {delta && (
          <View style={{ flexDirection: "row", marginLeft: 8 }}>
            {delta.value >= 0 ? <IncreaseDeltaSvg /> : <DecreaseDeltaSvg />}
            <Text style={styles.deltaPercentageText}>
              {delta.value}
              {delta.unit == DataMetricUnits.PERCENT && <Text>%</Text>}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { alignItems: "center", justifyContent: "flex-end" },
  titleText: {
    fontFamily: GLOBAL.FONT.Tertiary,
    fontSize: 18,
    color: GLOBAL.COLORS.primary_1,
  },
  valueText: {
    fontFamily: GLOBAL.FONT.Primary,
    color: GLOBAL.COLORS.primary_1,
    fontSize: 40,
  },
  valuePercentageText: { fontFamily: GLOBAL.FONT.DM_Sans_400, fontSize: 20 },
  deltaPercentageText: { fontFamily: GLOBAL.FONT.DM_Sans_400, fontSize: 16 },
});
