import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { GLOBAL } from "../styles/global";

export const Header = ({ headerText }: { headerText: string }) => {
  return (
    <View style={styles.headerBar}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          position: "absolute",
          left: 20,
        }}
      >
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="13.51" height="41" fill="#6CE4BF" />
          <rect
            y="27.2266"
            width="13.4531"
            height="34.4183"
            transform="rotate(-90 0 27.2266)"
            fill="#5DA7EB"
          />
          <rect x="27.3417" width="13.51" height="41" fill="#FFE768" />
        </svg>

        <Text
          style={{
            fontFamily: "DMSans_500Medium",
            color: GLOBAL.COLORS.primary_1,
            marginLeft: 20,
            fontSize: 32,
          }}
        >
          Habitual
        </Text>
      </View>
      <Text
        style={{
          alignSelf: "center",
          fontFamily: GLOBAL.FONT.Secondary,
          color: GLOBAL.COLORS.primary_1,
          fontSize: 18,
        }}
      >
        {headerText}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  headerBar: {
    flexDirection: "row",
    backgroundColor: GLOBAL.COLORS.white,
    height: 80,
    alignItems: "center",
    paddingHorizontal: 40,
    shadowOffset: { width: 4, height: 8 },
    shadowColor: "#295266",
    shadowOpacity: 0.1,
    shadowRadius: 17,
    justifyContent: "center",
  },
});
