import React, { useEffect, useState } from "react";
import { View, Text, ImageSourcePropType, Image } from "react-native";
import { ImageAsset } from "../types/server-types";
const thing = require("../../");

export const MosaicTile = ({ image }: { image: ImageAsset }) => {
  const [imageDimentions, setImageDimentions] = useState({
    width: 1,
    height: 1,
  });

  return (
    <Image
      resizeMode="contain"
      onLayout={() => {
        Image.getSize(image.url, (width, height) => {
          setImageDimentions({ width, height });
        });
      }}
      source={{ uri: image.url }}
      style={{
        width: imageDimentions.width,
        height: imageDimentions.height,
        alignSelf: "center",
      }}
    />
  );
};
