import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { signInWithGoogle } from "../helpers/firebaseHelper";
import "../styles/login.css";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const Login = () => {
  return (
    <div className="login">
      <div className="login__container">
        <button
          className="cta login__btn login__google"
          onClick={signInWithGoogle}
        >
          Login with Google
        </button>
      </div>
    </div>
  );
};
