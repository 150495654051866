export type DashboardData = {
  headerText: string;
  bannerData: DataMetric[];
  leaderboard: Leaderboard[];
  newsFeed: NewsFeed[];
};

export type DataMetric = {
  title: string;
  value: DataMetricValue;
  type: DataMetricType;
  delta?: DataMetricValue;
};

export enum DataMetricType {
  SALES = "Sales",
  ACTIVE = "Active",
  MAINTENANCE = "Maintenance",
  WEIGHTLOSS = "Weightloss",
  RETENTION = "Retention",
}

export type DataMetricValue = {
  value: number;
  unit?: DataMetricUnits;
};

export enum DataMetricUnits {
  PERCENT = "%",
  WEIGHT = "kg",
}

export type Leaderboard = {
  title: string;
  type: LeaderboardType;
  leaderboardData: LeaderboardRow[];
};

export type LeaderboardRow = {
  name: string;
  value: number;
};

export enum LeaderboardType {
  COINS = "Coins",
  WEIGHTLOSS = "Weightloss",
  STEPS = "Steps",
}

export type NewsFeed = {
  title: string;
  items: NewsFeedItem[];
};

export type NewsFeedItem = {
  type: NewsFeedItemType.IMAGE;
  image: ImageAsset;
};

export enum NewsFeedItemType {
  IMAGE = "IMAGE",
}

export enum NewsFeedTitle {
  POSTIVITY = "Positivity",
  TRUST = "Trustpilot",
  TEAM = "Team photos",
  FEEDBACK = "Feedback",
}

export type ImageAsset = {
  type?: ImageType;
  url: string;
};

export enum ImageType {
  SVG = "SVG",
  PNG = "PNG",
  JPG = "JPG",
  GIF = "GIF",
}
