import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { GLOBAL } from "../styles/global";
import { LeaderboardType } from "../types/server-types";
import { ScoreTypeIcon } from "./ScoreTypeIcon.component";

export const LeaderboardEntry = ({
  title,
  value,
  number,
  type,
  key,
}: {
  title: string;
  value: number;
  number: number;
  type: LeaderboardType;
  key: number;
}) => {
  return (
    <View key={key} style={styles.container}>
      <View
        style={{
          borderWidth: 0.5,
          width: "100%",
          marginBottom: 18,
          borderColor: GLOBAL.COLORS.majorGridLine,
        }}
      />
      <View
        style={{
          flexDirection: "row",
          display: "flex",
          flex: 1,
        }}
      >
        <Text style={styles.numberText}>{number}</Text>
        <Text style={styles.titleText}>{title}</Text>
        <ScoreTypeIcon type={type} value={value} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 18,
  },
  titleText: {
    fontFamily: GLOBAL.FONT.Tertiary,
    fontSize: 18,
    color: GLOBAL.COLORS.primary_1,
    flex: 10,
  },
  valueText: {
    fontFamily: GLOBAL.FONT.Secondary,
    color: GLOBAL.COLORS.primary_1,
    fontSize: 14,
  },
  numberText: {
    fontFamily: GLOBAL.FONT.Tertiary,
    color: GLOBAL.COLORS.primary_1,
    fontSize: 21,
    flex: 1,
  },
});
